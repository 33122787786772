<template>
  <div class="stds-textarea-container group" :class="containerClassRenderer">
    <textarea
      v-model="value"
      :disabled="disabled"
      class="stds-textarea scrollbar-form-1"
      :class="textareaClassRenderer"
      :placeholder="placeholder"
      :style="[maxLength ? { 'padding-bottom': '2.8rem' } : '']"
      @input="onInput"
    ></textarea>
    <span
      v-if="maxLength"
      class="pr-8 flex justify-end shrink-0 text-2xs leading-xs text-placeholder"
      :class="{ hidden: disabled }"
    >
      <em class="text-on-surface-elevation-2" :class="{ 'text-placeholder': value?.length === 0 }">
        {{ formatNumberMultipleWithCommas(value?.length ?? 0) }}
      </em>
      /{{ formatNumberMultipleWithCommas(Number(maxLength ?? 0)) }}
    </span>
  </div>
  <st-error-message
    :name="fieldName"
    :data="{ maxLength }"
    :showError="showFieldError"
    :class="{ 'absolute top-[100%] left-0': absoluteErrorMsg }"
  />
</template>

<script setup lang="ts">
import { computed, ref, toRefs, watch } from 'vue';

import StErrorMessage from '@/components/validation/st-error-message.vue';
import useValidation from '@/composables/useValidation';
import type { TextAreaProps } from '@/types/common/form.type';
import { formatNumberMultipleWithCommas } from '@/utils/currency.util';

const props = withDefaults(defineProps<TextAreaProps>(), {
  variant: 'outline',
  disabled: false,
  absoluteErrorMsg: false,
  size: 'lg'
});

const { rules } = toRefs(props);

const emit = defineEmits<{
  'update:modelValue': [v: string];
  change: [v: string];
}>();

const value = ref(props.modelValue);

const setFieldValue = ref<(value: string) => void>();

const containerClassRenderer = computed(() => ({
  'border-2 border-error focus-within:border-error': showFieldError.value,
  'border focus-within:border-2 focus-within:border-gray400 focus-within:pr-[0.6rem] focus-within:pb-[1.0rem]':
    !showFieldError.value && !props.maxLength,
  'border flex flex-col gap-4 bg-white focus-within:outline focus-within:outline-2 focus-within:-outline-offset-2 focus-within:outline-[#6892FF]':
    props.maxLength,
  'bg-neutral-variant-3': (!props.disabled || props.variant === 'outline') && !props.maxLength,
  '!bg-disabled-variant-3': props.disabled,
  'border-transparent !bg-neutral-variant-2': props.variant === 'fill',
  'border-border': props.variant === 'outline',
  [`${props.containerClass}`]: !!props.containerClass
}));

const textareaClassRenderer = computed(() => ({
  'text-text3 leading-btn2': props.size === 'sm',
  'text-text1 leading-btn1': props.size === 'lg',
  'flex-1': props.maxLength,
  'group-focus-within:-m-[0.1rem]': !showFieldError.value && !props.maxLength,
  [`${props.textareaClass}`]: !!props.textareaClass
}));

const fieldName = computed<string>(() => props.name ?? '');
const showFieldError = ref(false);

if (fieldName.value) {
  const { setValue, showError } = useValidation<string>({
    fieldName: fieldName.value,
    rules,
    value
  });

  setFieldValue.value = setValue;
  showFieldError.value = showError.value;

  watch(
    () => showError.value,
    (v: boolean) => {
      showFieldError.value = v;
    }
  );
}

watch(
  () => props.modelValue,
  (v?: string) => {
    value.value = v;
  }
);

const onInput = (e: Event) => {
  const { value: inputValue } = e.target as HTMLInputElement;
  handleModelUpdate(inputValue);
};

const handleModelUpdate = (e: string) => {
  if (setFieldValue.value) {
    setFieldValue.value(e);
  }
  emit('update:modelValue', e);
};
</script>
